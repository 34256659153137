<template>
  <v-bottom-navigation 
  v-model="value" 
  fixed 
  app
  color="white" 
  class="pt-2"
  style="background: #fff !important;"
  grow>
    <v-btn value="home" to="/home" color="white">
      <span style="color:#002060 !important;">Home</span>
      <v-icon color="#ff8800">mdi-home</v-icon>
    </v-btn>

    <v-btn value="checklists" to="/checklists" color="white">
      <span style="color:#002060 !important;">Checklists</span>
      <v-icon color="#ff8800">mdi-table-edit</v-icon>
    </v-btn>

    <v-btn value="incident" to="/safety" color="white">
      <span style="color:#002060 !important;">Safety</span>
      <v-icon color="#ff8800">mdi-security</v-icon>
    </v-btn>

    <v-btn value="projects" to="/projects" color="white">
      <span style="color:#002060 !important;">Projects</span>
      <v-icon color="#ff8800">mdi-package-variant</v-icon>
    </v-btn>

    <v-btn value="apps" to="/apps" color="white">
      <span style="color:#002060 !important;">More</span>
      <v-icon color="#ff8800">mdi-wrap</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>

export default {
  name: "BottomNavigation",
  data: () => ({ value: 'recent' }),
}
</script>