import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import customProject from '../components/Custom/ProjectsIcon.vue'
import customChecklist from '../components/Custom/Checklists.vue'
import customIncident from '../components/Custom/Incident.vue'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#002060',
            secondary: '#4F61AD',
            anchor: '#F8F8FB',
          },
        },
      },
    icons:{
      values:{
        customProjectIcon:{ component: customProject },
        customChecklistIcon:{ component: customChecklist },
        customIncidentIcon:{ component: customIncident }
      }
    }
});
