import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//const base_url = "http://localhost/api/v1"  //Local 
//const base_url = "http://139.162.182.254/laravel/camusat/public/api/v1"    //Live Demo
//const base_url = "https://camusatweb.ubuniworks.com/api/v1"    //Live
//const base_url = "https://camusattest.ubuniworks.com/laravel/camusat/public/api/v1"    //Test
const base_url = "https://paviconhseug.ubuniworks.com/api/v1"    //Test

export default new Vuex.Store({
  state: {
    projects: [],
    audits: [],
    currentAudit: {},
    reviewAudit:{},
    users: [],
    currentProject: {},
    ehs: [],
    currentEhs: {},
    qa: [],
    currentQa: {},
    leaveStatus: {},
    notifications: [],
    incidents: [],
    incident_types: [],
    currentPhoto: {},
    timesheet:""
  },
  mutations: { //synchrous
    setCurrentProject (state, payload){
      state.currentProject = payload
    }, 
    setAllUsers (state, payload) {
      state.users = payload
    },
    setAllProjects (state, payload) {
      state.projects = payload
    },
    setAllAudits (state, payload) {
      state.audits = payload
    },
    setCurrentAudit (state, payload) {
      state.currentAudit = payload
    },
    setReviewAudit (state, payload) {
      state.reviewAudit = payload
    },
    setCurrentEhs (state, payload) {
      state.currentEhs = payload
    },
    setAllEhs (state, payload) {
      state.ehs = payload
    },
    setCurrentQa (state, payload) {
      state.currentQa = payload
    },
    setAllQa (state, payload) {
      state.qa = payload
    },
    setAllNotifications (state, payload) {
      state.notifications = payload
    },
    setIncidents (state, payload) {
      state.incidents = payload
    },
    setIncidentTypes (state, payload) {
      state.incident_types = payload
    },
    setPhoto (state, payload) {
      state.currentPhoto = payload
    },
    setTimesheet (state, payload) {
      state.timesheet = payload
    },
    setLeaveStatus (state, payload) {
      state.leaveStatus = payload
    }
  },
  //{ headers : { "Access-Control-Allow-Origin": "*" } }
  actions: { //asynchrous
    async fetchAllProjects (state, payload) {
      const res = await fetch(base_url+'/projects/'+payload,{ mode: 'cors'})
      const data = await res.json()
      state.commit("setAllProjects",data)
    },
    async fetchAllAudits (state, payload) {
      const res = await fetch(base_url+'/audit/'+payload,{ mode: 'cors'})
      const data = await res.json()
      state.commit("setAllAudits",data)
    },
    async fetchAllUsers (state) {
      const res = await fetch(base_url+'/leave/users')
      const data = await res.json()
      state.commit("setAllUsers",data)
    },
    async fetchEhs (state, payload) {
      const res = await fetch(base_url+'/ehs/'+payload)
      const data = await res.json()
      if(data.status == true){
        state.commit("setAllEhs", data.ehs)
      }
    },
    async fetchQa (state, payload) {
      const res = await fetch(base_url+'/qa/'+payload)
      const data = await res.json()
      if(data.status == true){
        state.commit("setAllQa", data.qa)
      }
    },
    async fetchNotifications (state, payload) {
      const res = await fetch(base_url+'/notifications/'+payload)
      const data = await res.json()
      if(data.status == true){
        state.commit("setAllNotifications", data.notifications)
      }
    },
    async fetchIncident (state, payload) {
      const res = await fetch(base_url+'/incident/'+payload)
      const data = await res.json()
      if(data.status == true){
        state.commit("setIncidents", data.incidents)
      }
    },
    async fetchIncidentType (state) {
      const res = await fetch(base_url+'/incident/types')
      const data = await res.json()
      if(data.status == true){
        state.commit("setIncidentTypes", data.types)
      }
    },
    async fetchLeaveStatus (state, payload) {
      const res = await fetch(base_url+'/leave/'+payload)
      const data = await res.json()
      if(data.status == true){
        state.commit("setLeaveStatus", data.leave)
      }
    }
  },
  modules: {
  },
  getters: {
    getCurrentProject : state => state.currentProject,
    getAllProjects : state => state.projects,
    getAllAudits : state => state.audits,
    getAllUsers : state => state.users,
    getAllEhs : state => state.ehs,
    getCurrentEhs : state => state.currentEhs,
    getCurrentAudit : state => state.currentAudit,
    getReviewAudit : state => state.reviewAudit,
    getAllQa : state => state.qa,
    getCurrentQa : state => state.currentQa,
    getNotifications : state => state.notifications,
    getIncidents : state => state.incidents,
    getIncidentTypes : state => state.incident_types,
    getCurrentPhoto : state => state.currentPhoto,
    getTimesheet : state => state.timesheet,
    getLeaveStatus : state => state.leaveStatus,
    getTaskProgress: state => {
      if(state.currentProject.tasks.length > 0){
        let counter = 0;
        state.currentProject.tasks.forEach(element => {
          if(element.status == "complete"){
            counter = counter + 1
          }
        });
        return Math.round((counter/state.currentProject.tasks.length) * 100);
      }else{
        return 0;
      }
    }
  }
})
