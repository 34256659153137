import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Home.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/camera',
    name: 'Camera',
    component: () => import(/* webpackChunkName: "camera" */ '../components/Camera.vue')
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import(/* webpackChunkName: "projects" */ '../views/Projects.vue')
  },
  {
    path: '/projects/summary',
    name: 'ProjectSummary',
    component: () => import(/* webpackChunkName: "projectsummary" */ '../views/ProjectSummary.vue')
  },
  {
    path: '/projects/task',
    name: 'ProjectTask',
    component: () => import(/* webpackChunkName: "projecttask" */ '../views/ProjectTask.vue')
  },
  {
    path: '/checklists',
    name: 'Checklists',
    component: () => import(/* webpackChunkName: "checklists" */ '../views/Checklists.vue')
  },
  {
    path: '/checklists/ehs',
    name: 'EhsOverview',
    component: () => import(/* webpackChunkName: "EHSoverview" */ '../views/EhsOverview.vue')
  },
  {
    path: '/checklists/ehs/review',
    name: 'EhsReview',
    component: () => import(/* webpackChunkName: "EHSSingleReview" */ '../views/EhsReview.vue')
  },
  {
    path: '/checklists/qa',
    name: 'QaOverview',
    component: () => import(/* webpackChunkName: "QaOverview" */ '../views/QaOverview.vue')
  },
  {
    path: '/checklists/qa/review',
    name: 'QaReview',
    component: () => import(/* webpackChunkName: "QaSingleReview" */ '../views/QaReview.vue')
  },
  {
    path: '/incident',
    name: 'Incident',
    component: () => import(/* webpackChunkName: "incident" */ '../views/Incident.vue')
  },
  {
    path: '/incident/add',
    name: 'AddIncident',
    component: () => import(/* webpackChunkName: "addincident" */ '../views/AddIncident.vue')
  },
  {
    path: '/incident/view',
    name: 'ViewIncident',
    component: () => import(/* webpackChunkName: "addincident" */ '../views/ViewIncident.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
  },
  {
    path: '/safetypassport',
    name: 'SafetyPassport',
    component: () => import(/* webpackChunkName: "safetypassport" */ '../views/SafetyPassport.vue')
  },
  {
    path: '/passportTraining',
    name: 'PassportTraining',
    component: () => import(/* webpackChunkName: "passportTraining" */ '../views/PassportTraining.vue')
  },
  {
    path: '/passportCertificate',
    name: 'PassportCertificate',
    component: () => import(/* webpackChunkName: "passportCertificate" */ '../views/PassportCertificate.vue')
  },
  {
    path: '/passportAuthorization',
    name: 'PassportAuthorization',
    component: () => import(/* webpackChunkName: "passportAuthorization" */ '../views/PassportAuthorization.vue')
  },
  {
    path: '/passportRestriction',
    name: 'PassportRestriction',
    component: () => import(/* webpackChunkName: "passportRestriction" */ '../views/PassportRestriction.vue')
  },
  {
    path: '/apps',
    name: 'Apps',
    component: () => import(/* webpackChunkName: "apps" */ '../views/Apps.vue')
  },
  {
    path: '/leave',
    name: 'Leave',
    component: () => import(/* webpackChunkName: "leave" */ '../views/Leave.vue')
  },
  {
    path: '/leave/request',
    name: 'LeaveRequest',
    component: () => import(/* webpackChunkName: "leaverequest" */ '../views/LeaveRequest.vue')
  },
  {
    path: '/leave/status',
    name: 'LeaveStatus',
    component: () => import(/* webpackChunkName: "leavestatus" */ '../views/LeaveStatus.vue')
  },
  {
    path: '/safety',
    name: 'Safety',
    component: () => import(/* webpackChunkName: "safety" */ '../views/SafetyOverview.vue')
  },
  {
    path: '/addsafety',
    name: 'AddSafety',
    component: () => import(/* webpackChunkName: "addsafety" */ '../views/AddSafety.vue')
  },
  {
    path: '/audit/overview',
    name: 'AuditOverview',
    component: () => import(/* webpackChunkName: "auditoverview" */ '../views/AuditOverview.vue')
  },
  {
    path: '/audit/review',
    name: 'AuditReview',
    component: () => import(/* webpackChunkName: "auditreview" */ '../views/AuditReview.vue')
  },
  {
    path: '/audit/questions',
    name: 'AuditQuestions',
    component: () => import(/* webpackChunkName: "auditquestions" */ '../views/AuditQuestions.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
